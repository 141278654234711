import type {
	AnnotationContext,
	PortalDocumentsUploadPct,
	SavedDeviceIds,
	SelectedAdminFilters
} from '$lib/components/models';
import type { PersistentStore } from '$lib/svelte-persistent-store-main/src/index';
import type {
	Conversation,
	Client as ConversationsClient,
	Message,
	Participant
} from '@twilio/conversations';
import type {
	ContactCompany,
	Document,
	Notification,
	Portal,
	PortalUser,
	Subscription,
	Tag,
	Team,
	User
} from 'fixee-server';
import { NotificationType } from 'fixee-server/lib/services/notification/notification.shared';
import type {
	BrowserPermissions,
	PortalUserStatus
} from 'fixee-server/lib/services/portal/portal.shared';
import { getContext } from 'svelte';
import type { Writable } from 'svelte/store';
import type { LocalTrack, Room, Participant as VideoParticipant } from 'twilio-video';

export type AuthContextType = {
	user: Writable<User | undefined>;
	value: Writable<string>;
};

export type ApiStatus = 'connected' | 'disconnected' | 'authenticated';

export type RGPDAccept = {
	hasAcceptedCGU?: boolean;
	hasAcceptedCookies?: boolean;
};

export type GlobalVars = {
	openComingSoonModal?:
		| 'comingSoonAudioCall'
		| 'comingSoonPictureMode'
		| 'comingSoonRecordVocal'
		| 'comingSoonRequestVocal'
		| 'comingSoonRequestText';
	openVideoPermissionsWarning?: boolean;
};

export type WindowSize = {
	width: number;
	height: number;
};

export type NotificationsInfos = {
	open: boolean;
	loading: boolean;
	nbUnseen: number;
	nbUnseenIncomingPortals: number;
	filterHasBeenSeen: undefined | boolean;
	types: undefined | NotificationType[];
	portalRef_notifId_ToOpen: string | undefined;
	reloadNotifications: (notificationIds: string[]) => void;
	loadNotifications: (
		skip: number,
		filterHasBeenSeen: undefined | boolean,
		types?: NotificationType[]
	) => void;
	hasLoadedAllTheNotifications: boolean;
};

export type PortalPictureModeContext = {
	enabled: boolean;
	startAudio?: 'in-app' | 'phone'; // Which audio source to start with
};

export type PortalUISettings = {
	showHeader?: boolean;
	showChatInput?: boolean;
	showAssistant?: boolean;
	showBorder?: boolean;
	scrollToBottomForMessages?: boolean;
	isOnlyMobileUI?: boolean;
	canSwitchRoutes?: boolean;
	onTakeChargeRedirectToPortal?: boolean;
	adaptableWidth?: boolean;
};

// General contexts
export const getCurrentUserContext = () =>
	getContext<PersistentStore<User | undefined>>('currentUser');
export const getTeamsContext = () => getContext<Writable<Array<Team>>>('teams');
export const getCurrentTeamContext = () => getContext<PersistentStore<Team>>('currentTeam');
export const getCurrentSubscriptionContext = () =>
	getContext<PersistentStore<Subscription | undefined>>('currentSubscription');
export const getApiStatusContext = () => getContext<Writable<ApiStatus>>('apiStatus');
export const getBrowserPermissionsContext = () =>
	getContext<Writable<BrowserPermissions>>('permissions');
export const getDeviceIdsContext = () => getContext<Writable<SavedDeviceIds>>('deviceIds');
export const getRGPDAcceptContext = () => getContext<Writable<RGPDAccept>>('RGPDAccept');
export const getGlobalVarsContext = () => getContext<Writable<GlobalVars>>('globalVars');
export const getWindowSizeContext = () => getContext<Writable<WindowSize>>('windowSize');
export const getMyIssuerPortalsContext = () =>
	getContext<Writable<undefined | boolean>>('myIssuerPortals');

export const getAdminSelectedFiltersContext = () =>
	getContext<Writable<SelectedAdminFilters>>('adminSelectedFilters');

// Handler Tag
export const getTagsContext = () => getContext<Writable<Tag[]>>('tags');

// Handler Notification
export const getNotificationsContext = () => getContext<Writable<Notification[]>>('notifications');
export const getNotificationsInfosContext = () =>
	getContext<Writable<NotificationsInfos>>('notificationsInfos');

// Portal contexts
export const getPortalContext = () => getContext<Writable<Portal>>('portal');
export const getCurrentPortalUserContext = () =>
	getContext<Writable<PortalUser>>('currentPortalUser');
export const getIssuerPortalUserContext = () =>
	getContext<Writable<PortalUser | undefined>>('issuerPortalUser');
export const getPortalUsersStatusContext = () =>
	getContext<Writable<PortalUserStatus[]>>('portalUsersStatus');
export const getRoomContext = () => getContext<Writable<Room | null>>('room');
export const getMainParticipantContext = () =>
	getContext<Writable<VideoParticipant | undefined>>('mainParticipant');
export const getScreenShareContext = () =>
	getContext<
		Writable<{
			screenTrackId?: string;
			enabled: boolean;
		}>
	>('screenShare');
export const getChatClientContext = () =>
	getContext<Writable<ConversationsClient | undefined>>('chatClient');
export const getPortalConversationContext = () =>
	getContext<Writable<Conversation | undefined>>('portalConversation');
export const getPortalConversationMessagesContext = () =>
	getContext<Writable<Message[]>>('portalConversationMessages');
export const getPortalEditingMessageContext = () =>
	getContext<Writable<undefined | Message>>('portalEditingMessage');
export const getPortalMyParticipantContext = () =>
	getContext<Writable<Participant | undefined>>('portalMyParticipant');
export const getPortalParticipantsContext = () =>
	getContext<Writable<Participant[]>>('portalParticipants');
export const getPortalParticipantsTypingContext = () =>
	getContext<Writable<Participant[]>>('portalParticipantsTyping');
export const getPortalDocumentsContext = () => getContext<Writable<Document[]>>('portalDocuments');
export const getPortalTracksContext = () => getContext<Writable<LocalTrack[]>>('portalTracks');
export const getPortalLiveTokenContext = () =>
	getContext<Writable<string | undefined>>('portalLiveToken');
export const getPortalIsDesktopUiContext = () => getContext<Writable<boolean>>('portalIsDesktopUi');
export const getPortalShowLiveContext = () => getContext<Writable<boolean>>('portalShowLive');
export const getPortalPictureModeContext = () =>
	getContext<Writable<PortalPictureModeContext>>('portalPictureMode');
export const getPortalLiveMessageSidContext = () =>
	getContext<
		Writable<
			| undefined
			| {
					messageSid: string;
					conversationSid: string;
			  }
		>
	>('portalLiveMessageSid');
export const getPortalOpenedPictureModeMessageContext = () =>
	getContext<
		Writable<{
			message?: Message;
			open: boolean;
		}>
	>('portalOpenedPictureModeMessage');
export const getPortalAnnotationContext = () =>
	getContext<Writable<AnnotationContext | undefined>>('portalAnnotation');
export const getPortalShowAssistantContext = () =>
	getContext<Writable<boolean>>('portalShowAssistant');
export const getPortalUISettingsContext = () =>
	getContext<Writable<PortalUISettings>>('portalUISettings');

export const getPortalDocumentsUploadPctContext = () =>
	getContext<Writable<PortalDocumentsUploadPct>>('portalDocumentsUploadPct');

export const getPortalIsRecordingContext = () => getContext<Writable<boolean>>('portalIsRecording');

// Chatbot
export const getChatbotSelectedDocumentContext = () =>
	getContext<Writable<Document | undefined>>('chatbotSelectedDocument');

// Contacts
export const getContactCompaniesContext = () =>
	getContext<Writable<ContactCompany[]>>('contactCompanies');

export const getPortalTokenContext = () => getContext<Writable<string | undefined>>('portalToken');
